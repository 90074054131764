/** Main Layout of the app, as well as coordinating some global state for now */
import { setUser, captureEvent } from "@sentry/nextjs";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useSetAtom, useAtom } from "jotai";
import Image from "next/image";
import { Chrome } from "react-feather";
import { ModalStack } from "./modal/ModalStack";
import { useAuth } from "./auth/useAuth";
import LoginButton, { PrivacyPolicyButton } from "./auth/LoginButton";
import {
  hasServiceWorkerSupport,
  isClientSide,
  isLocal,
  isMobileWidth,
  isPersistenceEnabled,
  isRunningInIOSWebview,
  isIOs,
} from "./utils/environment";
import { pwaEventAtom, InstallBanner } from "./components/InstallBanner";
import { APP_STORE_URL } from "./utils/constants";
import { userEmailAtom, userIdAtom } from "./model/atoms";
import { useColorScheme } from "./settings/ColorScheme";
import { LogoutDevShortcut, useLogoutListener } from "./auth/Logout";
import { ToastContainer } from "./components/Toast";
import useAnalytics from "./analytics/useAnalytics";
import { colors, mobileWidth, tabletWidth } from "./utils/style";
import { useUserInfo } from "./model/userInfo";
import logger from "./utils/logger";
import { useTodoStrikethroughWatcher } from "./settings/SettingsModalContent";
import useTrackMemory from "./analytics/useTrackMemory";
import { Logo } from "./components/Icons/Logo";

const LayoutRoot = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  padding: 0 0 32px 0;
  width: 100vw;
  max-width: 100%;
  overflow: hidden scroll;
  height: -webkit-fill-available;
`;

export const MainLayout: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { getUserInfo } = useUserInfo();
  useAnalytics();
  useTrackMemory();
  useColorScheme();
  useLogoutListener();
  useTodoStrikethroughWatcher();

  const setUserId = useSetAtom(userIdAtom);
  const setUserEmail = useSetAtom(userEmailAtom);

  useEffect(() => {
    if (!isAuthenticated) {
      setUser(null);
      return;
    }

    getUserInfo().catch((e) => logger.warn("Couldn't retrieve user info"));

    if (user && user.email) {
      setUserId(user.id);
      setUserEmail(user.email);
      setUser({
        id: user.email,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      });
    }
  }, [isAuthenticated, user, getUserInfo, setUserId, setUserEmail]);

  const colorScheme = useColorScheme();
  const isSupportedBrowser = isRunningInIOSWebview || hasServiceWorkerSupport;
  const isInAppBrowser = /FBAV|FBAN|FBIOS|FB_IAB|FB4A|FBCR|FBMD|FBSN|FBSV|FBSS|FBCA|FBID|FBLC|FBRV|Instagram/.test(
    navigator.userAgent,
  );

  if (isPersistenceEnabled && !isSupportedBrowser && isClientSide) {
    captureEvent({
      message: `ideaflow.app accesed from unsupported browser: ${navigator.userAgent}`,
      tags: { category: "browser-not-supported" },
      level: "warning",
      fingerprint: ["browser-not-supported", navigator.userAgent],
    });

    return (
      <LayoutRoot role="presentation">
        <span>
          {isInAppBrowser
            ? "In-app browsers on platforms such as Facebook and Instagram are not supported."
            : "Your browser is not supported. Please use another browser such as Chrome, Safari, Firefox, Edge, or Opera."}
        </span>
        <>
          <IOSDownloadLink />
        </>
      </LayoutRoot>
    );
  }

  if (isLoading) {
    return <LayoutRoot role="presentation">Loading</LayoutRoot>;
  }

  if (!isAuthenticated) {
    return (
      <LayoutRoot role="presentation">
        <NavBar>
          {isRunningInIOSWebview ? (
            <a href="https://ideaflow.io" target="_blank" rel="noopener noreferrer">
              <Logo fill={colorScheme !== "dark" ? "black" : "white"} height="50" />
            </a>
          ) : (
            <>
              <a href="https://ideaflow.io" target="_blank" rel="noopener noreferrer">
                <Image
                  alt="logo"
                  src={colorScheme !== "dark" ? "/logo-light.svg" : "/logo-dark.svg"}
                  width="96px"
                  height="17px"
                ></Image>
              </a>
              <NavLinkContainer>
                <NavLink href="https://ideaflow.io" target="_blank" rel="noopener noreferrer">
                  Company
                </NavLink>
                <NavLink href="https://ideaflow.io/careers" target="_blank" rel="noopener noreferrer">
                  Careers
                </NavLink>
              </NavLinkContainer>
              <RightAlignedDiv>
                <LoginSecondary />
              </RightAlignedDiv>
            </>
          )}
        </NavBar>
        <StyledH1>
          Zero-Friction
          <br />
          AI Notebook
        </StyledH1>
        <StyledH2>Ideaflow—the super-fast, searchable stack of your thoughts.</StyledH2>
        <HeroContainer>
          <LoginPrimary />
          <InstallDownloadButton />
        </HeroContainer>
        {!isMobileWidth && (
          <ScreenshotsContainer>
            <ScreenshotBox>
              <InnerBox>
                <Image
                  alt="Desktop screenshot"
                  src={colorScheme !== "dark" ? "/app-light.jpg" : "/app-dark.jpg"}
                  width="1440px"
                  height="788px"
                  layout="responsive"
                  loader={isRunningInIOSWebview ? ({ src }) => src : undefined}
                  style={{
                    borderRadius: "24px",
                  }}
                ></Image>
              </InnerBox>
            </ScreenshotBox>
            <ScreenshotBox style={{ position: "absolute", right: "-48px", top: "50px" }}>
              <InnerBox>
                <Image
                  alt="Mobile screenshot"
                  src={colorScheme !== "dark" ? "/mobile-light.jpg" : "/mobile-dark.jpg"}
                  style={{
                    borderRadius: "24px",
                  }}
                  width="232px"
                  height="524px"
                  loader={isRunningInIOSWebview ? ({ src }) => src : undefined}
                ></Image>
              </InnerBox>
            </ScreenshotBox>
          </ScreenshotsContainer>
        )}

        <div role="presentation" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {!isRunningInIOSWebview && <InfoText>Fully supported on Chrome and Safari on iOS and desktop</InfoText>}
          <PrivacyPolicyButton />
        </div>
      </LayoutRoot>
    );
  }

  return (
    <LayoutRoot role="presentation">
      <InstallBanner />
      {isLocal && <LogoutDevShortcut />}
      <ModalStack />
      <ToastContainer />
      {children}
    </LayoutRoot>
  );
};

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  font-size: 20px;
  line-height: 32px;
  background-color: var(--color-bg-tertiary);
  color: var(--color-text-primary);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.1s;

  &:hover {
    background-color: var(--color-bg-accent-tertiary);
    color: var(--color-text-accent);
  }
`;

const InstallDownloadButton = () => {
  const [pwaEvent] = useAtom(pwaEventAtom);

  if (isIOs && !isRunningInIOSWebview) {
    return <IOSDownloadLink />;
  }

  if (pwaEvent) {
    return (
      <Button onClick={() => pwaEvent.prompt()}>
        <Chrome />
        Install App
      </Button>
    );
  }

  return null;
};

const NavBar = styled.nav`
  width: 90%;
  max-width: 1140px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: var(--header-height);
  padding: 16px;
  align-items: center;

  ${isRunningInIOSWebview &&
  `@media (max-width: ${tabletWidth}px) {
    flex-direction: row;
    justify-content: center;
    margin-top: 32px; 
  }

  @media (max-width: ${mobileWidth}px) {
    margin-top: 27%;
  }`}
`;

const NavLinkContainer = styled.div`
  display: flex;
  gap: 24px;
  text-align: center;
`;

const RightAlignedDiv = styled.div`
  width: 96px;
  display: flex;
  justify-content: right;
`;

const HeroContainer = styled.div`
  display: ${isMobileWidth ? "flex" : "flex"};
  flex-direction: ${isMobileWidth ? "column" : "row"};
  align-items: center;
  margin: 0 auto;
  gap: 16px;
  padding-bottom: 64px;
  padding-top: 16px;
`;

const ScreenshotsContainer = styled.div`
  position: relative;
  left: -24px;
  width: 80vw;
  max-width: 1080px;
`;

const ScreenshotBox = styled.div`
  border: solid 1px var(--color-bg-tertiary);
  background-color: var(--color-bg-primary);
  border-radius: 32px;
  padding: 8px;
  margin-bottom: 24px;
`;
const InnerBox = styled.div`
  border: solid 1px var(--color-bg-tertiary);
  border-radius: 24px;
  box-shadow:
    0 0 0 1px var(--color-bg-accent-tertiary),
    0 1px 1px -0.5px #2a334608,
    0 2px 2px -1px #2a33460a,
    0 3px 3px -1.5px #2a33460a,
    0 5px 5px -2.5px #2a334608,
    0 10px 10px -5px #2a334608,
    0 24px 24px -8px #2a334608,
    0 0px 24px -1px var(--color-bg-accent-tertiary);
`;

const InfoText = styled.div`
  font-size: 16px;
  color: ${colors.text.secondary};
  text-align: center;
  padding: 10px 10px;
  max-width: ${isMobileWidth ? "250px" : "600px"};
`;

const StyledH1 = styled.div`
  color: var(--color-text-primary);
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  line-height: 72px;
  margin: 64px auto 0 auto;
  background: linear-gradient(36deg, #57b8d3, #d8f9ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: ${mobileWidth}px) {
    font-size: 48px;
    line-height: 56px;
    margin: 32px auto 0 auto;
  }
`;

const StyledH2 = styled.div`
  color: var(--color-text-primary);
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  max-width: 400px;
  text-align: center;
  margin: 20px auto;

  @media (max-width: ${mobileWidth}px) {
    font-size: 18px;
    line-height: 28px;
    max-width: 300px;
  }
`;

const NavLink = styled.a`
  color: var(--color-text-primary);
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  transition: color 0.1s;

  &:hover {
    color: var(--color-text-accent);
  }
`;

const IOSDownloadLink = (): JSX.Element | null => {
  const Button = styled.a`
    padding: 8px 16px;
    font-size: 20px;
    line-height: 32px;
    background-color: var(--color-bg-tertiary);
    color: var(--color-text-primary);

    text-decoration: none;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    transition:
      background-color 0.3s,
      color 0.1s;

    &:hover {
      background-color: var(--color-bg-accent-tertiary);
      color: var(--color-text-accent);
      box-shadow:
        0 0 0 1px #0e3f7e04,
        0 1px 1px -0.5px #2a334608,
        0 2px 2px -1px #2a334608,
        0 3px 3px -1.5px #2a334608,
        0 5px 5px -3px #2a334608;
    }
  `;

  const Span = styled.span`
    padding: 0 8px 0 0;
  `;
  return (
    <Button href={APP_STORE_URL}>
      <Span> </Span> Download our app
    </Button>
  );
};

const LoginPrimary = (): JSX.Element => {
  return <TealButton autoFocus />;
};

const TealButton = styled(LoginButton)`
  background-color: var(--color-bg-accent-primary);
  padding: 8px 16px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  border-radius: 8px;
  border: none;
  transition: background-color 0.1s;

  &:hover {
    background-color: var(--color-button-bg);
    box-shadow:
      0 0 0 1px #0e3f7e04,
      0 1px 1px -0.5px #2a334608,
      0 2px 2px -1px #2a334608,
      0 3px 3px -1.5px #2a334608,
      0 5px 5px -3px #2a334608;
  }
  &:focus {
    outline: 4px solid var(--color-bg-accent-secondary);
    outline-offset: 0px;
  }
`;

const LoginSecondary = (): JSX.Element => {
  return <GrayButton />;
};

const GrayButton = styled(LoginButton)`
  padding: 0px 8px;
  font-size: 16px;
  line-height: 32px;
  background-color: var(--color-bg-tertiary);
  color: var(--color-text-primary);
  border: none;
  text-decoration: none;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  transition:
    background-color 0.3s,
    color 0.1s;

  &:hover {
    background-color: var(--color-bg-accent-tertiary);
    color: var(--color-text-accent);
    box-shadow:
      0 0 0 1px #0e3f7e04,
      0 1px 1px -0.5px #2a334608,
      0 2px 2px -1px #2a334608,
      0 3px 3px -1.5px #2a334608,
      0 5px 5px -3px #2a334608;
  }
`;
